import { Center, Container, Heading } from "@chakra-ui/react";
import { graphql, PageProps } from "gatsby";
import React, { FC } from "react";
import Card from "src/atoms/Card";
import Layout from "src/components/Layout";
import Markdown from "src/components/Markdown";
import Picture from "src/components/Picture";
import StructuredText from "src/components/StructuredText";

const Memoriam: FC<PageProps<Gatsby.MemoriamByIdQuery>> = ({
  data: { datoCmsMember },
}) => {
  if (!datoCmsMember) return null;

  return (
    <Layout>
      <Container as="section" maxW="container.lg">
        <Card size="lg">
          <Heading textAlign="center">{`${datoCmsMember.firstName} ${datoCmsMember.lastName}`}</Heading>

          <Heading color="gray.700" size="lg" textAlign="center">
            {datoCmsMember.dates}
          </Heading>

          <Center>
            <Picture
              borderRadius="full"
              image={datoCmsMember.portrait?.medium}
              my={12}
            />
          </Center>

          <Markdown>
            <StructuredText data={datoCmsMember.tribute} />
          </Markdown>
        </Card>
      </Container>
    </Layout>
  );
};

export default Memoriam;

export const query = graphql`
  query MemoriamById($id: String) {
    datoCmsMember(originalId: { eq: $id }) {
      ...Member
    }
  }
`;

/**
 * 1936-2020
 */
